import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { get_data, put_data, post_data } from "../../../actions/index";
import { Toast } from "primereact/toast";
import Swal from "sweetalert2";
import SendMessages from "../../../components/modals/sendMessage";
import Mapa from "../../../components/generals/map";
import Header from "../../../components/generals/header";
import Pie from "../../../components/generals/charts/pie";
import ModalApps from "../../../components/modals/modalApps";
import KnobChart from "../../../components/generals/charts/knob";
import VerticalBar from "../../../components/generals/charts/verticalBar";
import BoxChart from "../../../components/generals/boxCharts";
import LockedDevices from "../../../components/modals/lockeds";
import Info from "../../../components/Dashboards/info";
import ModalInforms from "../../../components/modals/modalInforms";
import ModalBackgroundChange from "../../../components/modals/modalBackgroundChange";
import ModalContentDownload from "../../../components/modals/modalContentDownload";
import { formatDate } from "../../../components/generals/charts/utils/DatesFormats";
import { ProgressSpinner } from "primereact/progressspinner";
import { NavLink } from "react-router-dom";
import SpeedDialMenu from "../../../components/Dashboards/speedDialMenu";
import infoCard from "./JSON/info.json";
import formatConnections from "./Assets/formatConnections";
import styles from "../../../css/Dashboards/DashDevices.module.css";
import styleChecks from "../../../css/generals/Checkbox.module.css";

export default function Dashboard() {
  //? urls de la API
  const { id } = useParams();
  const [deviceUrl] = useState("device/data");
  const [preferenceUrl] = useState("preferences");

  const [statUrl] = useState("device/stats");
  const [locationsUrl] = useState("device/last_location");
  const [connectionsUrl] = useState("device/connections");
  const [trackingsUrl] = useState("device/trackings");
  const [applicationsUrl] = useState("device/applications");

  //*estados de componentes
  const [device, setDevice] = useState();
  const [stat, setStat] = useState();
  const [locations, setLocations] = useState();
  const [connections, setConnections] = useState();
  const [applications, setApplications] = useState();
  const [tracking, setTracking] = useState();
  const [preferences, setPreferences] = useState();

  const toast = useRef(null);
  const [action, setAction] = useState();
  const [labelConn, setLabelConn] = useState();

  //Estados para mostrar o no los modales
  const [modalsShow, setModalsShow] = useState({
    message: false,
    lockeds: false,
    reports: false,
    apps: false,
    backgroundChange: false,
    contentDownload: false,
  });

  //* llamadas a API
  useEffect(() => {
    getData(deviceUrl, id, setDevice);
  }, [deviceUrl, id]);
  useEffect(() => {
    getData(preferenceUrl, 1, setPreferences);
  }, [preferenceUrl, id]);
  useEffect(() => {
    getData(statUrl, id, setStat);
  }, [statUrl, id]);
  useEffect(() => {
    getData(locationsUrl, id, setLocations);
  }, [locationsUrl, id]);
  useEffect(() => {
    getData(connectionsUrl, id, setConnections);
  }, [connectionsUrl, id]);
  useEffect(() => {
    getData(applicationsUrl, id, setApplications);
  }, [applicationsUrl, id]);
  useEffect(() => {
    getData(trackingsUrl, id, setTracking);
  }, [trackingsUrl, id]);

  //*lanza alerta de alarma y hace el disable
  useEffect(() => {
    disabledAlarms(stat);
  }, [stat]);

  //*efecto de label de connection
  useEffect(() => {
    if (connections) {
      let label = formatConnections(connections);
      setLabelConn(label);
    }
  }, [connections]);

  //*efecto de formateo de fecha de la última conexión
  useEffect(() => {
    if (device) device.last_date = formatDate(device.last_date);
    setDevice(device);
  }, [device]);

  //*llamador de datos de API
  const getData = async (url, data, set) => {
    const response = await get_data(url, data);
    if (response.status === 200){
      set(response.data);
    } else {
      set({error: true, code: response.code});
    } 
  };

  //*funcion de desactivacion de alarmas por defecto
  async function disabledAlarms(stat) {
    if (stat) {
      if (stat.checkAlert) {
        let json = {
          id: stat.id,
          checkAlert: false,
        };

        await put_data("stats", json);

        toast.current.show({
          severity: "error",
          summary: "Alerta de uso excesivo!",
          sticky: true,
        });
      }
    }
  }
  
  //?Va seteando el estado para reenderizar los modales
  function handleModal(type, boolean) {
    if (type === "bloquear" || type === "desbloquear") {
      setAction(type);
      setModalsShow({ ...modalsShow, lockeds: boolean });
    } else {
      setModalsShow({ ...modalsShow, [type]: boolean });
    }
  }

  //*funcion de recarga de device
  const getDevice = async () => {
    await getData(deviceUrl, id, setDevice);
  };

  //*funcion de modales
  async function deleted(action) {
    let jsonAction = {
      alarm: false,
      touch: action === "activar" ? false : true,
      screen: action === "activar" ? false : true,
      background: false,
      usb: false,
      messageTitle: "",
      messageBody: "",
      changePassword: false,
      pass1: "",
      pass2: "",
      photo: {
        status: false,
        resolution: "low",
        recursive: false,
        time: 0,
      },
      brightness: {
        status: false,
        percentage: 50,
      },
    };

    let json = {
      action: "lock",
      data: {
        statusLock: action === "desactivar" ? true : false,
        order_id: Math.floor(Math.random() * (1000000000000000 - 10 + 1) + 10),
        preferences: jsonAction,
      },
      devicesId: [parseInt(id)],
      groupsId: [],
    };

    if (action === "desactivar") {
      device.motive_lock = "disabled";
    } else if (action === "activar") {
      device.motive_lock = null;
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: `Esta seguro que desea ${
          action === "activar" ? "activar" : "desactivar"
        } momentáneamente el dispositivo?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let { data } = await post_data("actions", [json]);
          await put_data("device", device);
          if (data[0]?.result) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Acción realizada con éxito!",
              text: `${
                action === "activar" ? "Activacion" : "Desactivacion"
              } exitosa!`,
              showConfirmButton: false,
              timer: 2000,
            });
            if (data[1]?.sendings.length > 0) setDevice();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Error. No se pudo realizar la acción!",
              text: `${
                action === "activar" ? "Activacion" : "Desactivacion"
              } fallida!`,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      });
  }

  return (
    <div
      className="content-wrapper containerHeight"
      style={{ padding: "2rem 1.5rem" }}
    >
      {device && (
        <SpeedDialMenu
          onHide={handleModal}
          data={device}
          deleted={deleted}
          type="devices"
          id={id}
          identity={device.identity}
        />
      )}
      {device && (
        <div>
          <Toast ref={toast} position="bottom-right" />
          <Header
            title={device.name}
            edit={true}
            data={device}
            type="device"
            reload={getDevice}
          />
        </div>
      )}
      <section>
        <div className={styles.GridDashboard}>
          {device === undefined ? (
            <div className={styles.containerBoxChart}>
              <ProgressSpinner className={styles.spinner} />
            </div>
          ) : device?.error? (<h5>Sin data a mostrar</h5>) : (
            <div className={(styles.contents2, styles.headerDash, styles.info)}>
              {device &&
                infoCard.map((el) => {
                  if (el.compare)
                    if (Array.isArray(el.key)) {
                      let key = "statusLock";
                      let value = "Pendiente...";
                      let icon;
                      if (device.status_lock && device.motive_lock)
                        value = "Bloqueado";
                      if (!device.status_lock && !device.motive_lock)
                        value = "Desbloqueado";

                      value === "Bloqueado"
                        ? (icon = el.icon[0])
                        : value === "Desbloqueado"
                        ? (icon = el.icon[1])
                        : (icon = el.icon[2]);
                      return (
                        <Info
                          key={key}
                          title={el.title}
                          value={value}
                          icon={icon}
                          className={styles.red}
                          width="100%"
                        />
                      );
                    } else {
                      let icon;
                      device.so.includes("Android")
                        ? (icon = el.icon[0])
                        : (icon = el - icon[1]);
                      return (
                        <Info
                          key={el.key}
                          title={el.title}
                          value={device[el.key]}
                          icon={icon}
                          className={styles.red}
                          width="100%"
                        />
                      );
                    }
                  else {
                    return (
                      <Info
                        key={el.key}
                        title={el.title}
                        value={device[el.key]}
                        icon={el.icon[0]}
                        className={styles.red}
                        width="100%"
                      />
                    );
                  }
                })}
            </div>
          )}
          <div className={styles.containerStats}>
            <BoxChart width="100%" title="Disco" icon="fa-solid fa-floppy-disk">
              { stat === undefined ? (
                <ProgressSpinner />
              ) : stat?.error || stat.length === 0 ? (
                <h5>Sin data a mostrar</h5> 
              ) : (
                <div className={styles.containerBoxChart}>
                  <label className={styleChecks.sliderLabel}>
                    Cantidad de alertas:
                    {stat.countOverDisk === null || stat.countOverDisk === 0
                      ? 0
                      : stat.countOverDisk}
                  </label>

                  <Pie
                    type={"individual"}
                    data={{ disk: stat.Disk[stat.Disk.length - 1] }}
                    maxWidth="25rem"
                  />
                </div>
              )}
            </BoxChart>
            <BoxChart
              width="100%"
              padding="5rem auto"
              title="Ram"
              icon="fa-solid fa-memory"
            >
              {stat === undefined ? (
                <ProgressSpinner />
              ) : preferences === undefined ? (
                <ProgressSpinner />
              ) : stat?.error || preferences?.error || stat.length === 0 ?(
                <h5>Sin data a mostrar</h5>
              ):(
                <div className={styles.containerBoxChart}>
                  <label className={styleChecks.sliderLabel}>
                    Cantidad de alertas:
                    {stat.countOverRam === 0 || stat.countOverRam === null
                      ? 0
                      : stat.countOverRam}
                  </label>
                  <KnobChart
                    data={{ ram: stat.Ram[stat.Ram.length - 1] }}
                    alert={preferences.preferencesReport.percentRam}
                    type="individual"
                    size={150}
                  />
                </div>
              )}
            </BoxChart>
          </div>
          <div className={styles.contents}>
            <BoxChart
              width="100%"
              heightBody="80%"
              title="Conexiones"
              icon="fa-solid fa-mobile"
            >
              {connections === undefined ? (
                <ProgressSpinner />
              ) : connections?.error || connections.length === 0 ? ( 
                <h5>Sin data a mostrar</h5>
              ): labelConn ? (
                <VerticalBar
                  data={labelConn.data}
                  labels={labelConn.labels}
                  backgroundColor={labelConn.backgroundColor}
                  title={"Conexiones"}
                />
              ): (<></>)}
            </BoxChart>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.bodyDash}>
          <BoxChart
            width="100%"
            title="Geoposicionamiento"
            icon="pi pi-map-marker"
            id={id}
          >
            {locations === undefined ? (
              <ProgressSpinner />
            ) : locations?.error || locations.length === 0 ? (
              <h5>Sin data a mostrar</h5>
            ):(
              locations && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to={`/mdm/history/${id}`}
                    className={`btn btn-dark ${styles.navLinkLastLocation}`}
                    type="button"
                  >
                    <i
                      className="pi pi-map-marker"
                      style={{ marginRight: "0.5rem" }}
                    ></i>
                    Ver más ubicaciones
                  </NavLink>
                  <Mapa info={locations?.last_location} type="last" />
                </div>
              )
            )}
          </BoxChart>
        </div>
      </section>
      {device && (
        <>
          {/*//*MODAL APLICACIONES*/}
          {applications && (
            <ModalApps
              show={modalsShow.apps}
              onHide={() => handleModal("apps", false)}
              data={applications}
              type="devices"
              identity={device.identity}
            />
          )}
          {/*//*MODAL INFORMES*/}
          {tracking && (
            <ModalInforms
              id={id}
              data={tracking}
              show={modalsShow.reports}
              onHide={() => handleModal("reports", false)}
            />
          )}

          {/*//*MODAL BLOQUEAR*/}

          {preferences && (
            <LockedDevices
              device={device}
              so={device.so}
              preferences={preferences.defaultThief}
              configDefault={preferences.defaultThief.default}
              action={action}
              show={modalsShow.lockeds}
              onHide={() => handleModal("lockeds", false)}
              devices={[parseInt(id)]}
              reload={getDevice}
              title={`Esta seguro que quiere ${action} el dispositivo?`}
              identity={device.identity}
            />
          )}

          {/*//*MODAL ENVIAR MENSAJE*/}
          <SendMessages
            title="Enviar Mensaje"
            show={modalsShow.message}
            onHide={() => handleModal("message", false)}
            devices={[parseInt(id)]}
            identity={device.identity}
            component="dashboardDevices"
          />

          {/*//*MODAL CAMBIO DE FONDO DE PANTALLA*/}
          <ModalBackgroundChange
            show={modalsShow.backgroundChange}
            onHide={() => handleModal("backgroundChange", false)}
            title="Cambiar Fondo de Pantalla"
            btnError="Cerrar"
            btnSuccess="Enviar"
            type="devices"
            identity={device.identity}
          />

          {/*//*MODAL DE DESCARGA DE CONTENIDO*/}
          <ModalContentDownload
            show={modalsShow.contentDownload}
            onHide={() => handleModal("contentDownload", false)}
            title="Descargar Contenido"
            btnError="Cerrar"
            btnSuccess="Enviar"
            type="devices"
            identity={device.identity}
          />
        </>
      )}
    </div>
  );
}

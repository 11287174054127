import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "../../../css/generals/charts/pie.css";
import styles from "../../../css/generals/charts/Pie.module.css";

export default function Pie({ data, type, maxWidth, alert }) {
  // console.log(data);
  const [disk, setDisk] = useState({
    disponible: 1,
    utilizado: 0,
  });
  let percent = porcentage();
  useEffect(() => {
    if (percent >= 0 && percent <= 100)
      setDisk({ disponible: percent, utilizado: 100 - percent });
  }, [percent]);

  function bytesToSize(bytes) {
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2);
  }

  function porcentage() {
    let total = 0;
    let available = 0;

    if (data !== null)
      if (type === "general") {
        for (const disk of data.disk) {
          total += parseFloat(disk.total);
          available += parseFloat(disk.available);
        }
      } else if (type === "individual") {
        total = bytesToSize(data.disk.total);
        available = bytesToSize(data.disk.available);
      }

    return Math.round((available * 100) / total);
  }

  const chartData = {
    labels: [`Disponible`, `Utilizado`],
    datasets: [
      {
        data: [disk.disponible, disk.utilizado],
        backgroundColor: [
          alert && alert < disk.utilizado ? "#FFB5B5" : "#007AFF",
          alert && alert < disk.utilizado ? "#FF3023" : "#003686",
        ],
      },
    ],
  };

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  });

  return (
    <>
      <Chart
        type="pie"
        data={chartData}
        options={lightOptions}
        style={{ position: "relative", maxWidth: maxWidth }}
        className={`${styles.statDisk}`}
      />
    </>
  );
}

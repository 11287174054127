import React, { useState, useEffect } from "react";
import Header from "../../components/generals/header";
import styles from "../../css/reports/Reports.module.css";
import Doughnuts from "../../components/generals/charts/doughnut";
import Pie from "../../components/generals/charts/pie";
import BoxChart from "../../components/generals/boxCharts";
import KnobChart from "../../components/generals/charts/knob";
import Info from "../../components/Dashboards/info";
import VerticalBar from "../../components/generals/charts/verticalBar";
import Line from "../../components/generals/charts/line";
import DataTableLazyDevices from "../../components/generals/datatables/dataTableLazyDevices";
import { ProgressSpinner } from "primereact/progressspinner";
import { currentAndPreviousDate } from "../../components/generals/charts/utils/DatesFormats";
import { get_data } from "../../actions/index";

export default function Reports() {
  const [querys, setQuerys] = useState([]);
  const [searchLast, setSearchLast] = useState([]);
  const [searchMost, setSearchMost] = useState([]);

  const [loadingCharts, setLoadingCharts] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [charts, setCharts] = useState(null);
  const [stats, setStats] = useState(null);

  let date = currentAndPreviousDate(6, "-");
  let day = new Date(date[1]).toISOString().split("T")[0];

  const getDataCharts = async () => {
    setLoadingCharts(true);
    let data = await get_data("reports/charts", "connections");
    if (data) setCharts(data?.data);
    else setCharts([]);
    setLoadingCharts(false);
  };

  const getDataStats = async () => {
    setLoadingStats(true);
    let data = await get_data("reports/charts", "stats");
    if (data) setStats(data?.data);
    else setStats([]);
    setLoadingStats(false);
  };

  const getQueryList = async (date) => {
    let query = await get_data("reports/safeweb/queryList", date);
    if (query) setQuerys(query.data);
  };

  const getSafeWebLast = async () => {
    let last = await get_data("reports/safeweb/search", "searchLast");
    if (last) setSearchLast(last.data);
  };

  const getSafeWebMost = async () => {
    let most = await get_data("reports/safeweb/search", "searchMost");
    if (most) setSearchMost(most.data);
  };

  useEffect(() => {
    getDataCharts();
    getDataStats();
    getQueryList(day);
    getSafeWebLast();
    getSafeWebMost();
  }, [day]);

  //últimas 3 urls buscadas

  let top3 = searchLast;

  //Top 10 de las urls más buscadas
  let top10 = searchMost;

  const labelsTop10 = top10?.map(({ url }) => {
    return url;
  });

  const dataTop10 = top10?.map(({ count }) => {
    return count;
  });

  return (
    <>
      <div className={styles.main_container}>
        <div className="container-fluid">
          <Header title="Reportes" />
          <div className={styles.spaceCharts}>
            <BoxChart
              width="100%"
              heightBody="80%"
              height="100% !important"
              title="Disco"
              icon="fa-solid fa-floppy-disk"
            >
              {loadingStats && <ProgressSpinner />}
              {stats !== null && stats.length !== 0 && (
                <Pie data={stats} type="general" maxWidth="250px" />
              )}
              {stats !== null && stats.length === 0 && (
                <h5>Sin data a mostrar</h5>
              )}
            </BoxChart>
            <BoxChart
              width="100%"
              heightBody="80%"
              height="100% !important"
              title="Ram"
              icon="fa-solid fa-memory"
            >
              {loadingStats && <ProgressSpinner />}
              {stats != null && stats.length !== 0 && (
                <KnobChart data={stats} type="general" size={200} />
              )}
              {stats !== null && stats.length === 0 && (
                <h5>Sin data a mostrar</h5>
              )}
            </BoxChart>
            <BoxChart
              width="100%"
              heightBody="80%"
              height="100% !important"
              title="Conexiones de Equipos"
              icon="pi pi-tablet"
            >
              {loadingCharts && <ProgressSpinner />}
              {charts !== null && charts.length !== 0 && (
                <Doughnuts maxWidth={"270px"} data={charts} />
              )}
              {charts !== null && charts.length === 0 && (
                <h5>Sin data a mostrar</h5>
              )}
            </BoxChart>
          </div>
          <div className={styles.dataTL}>
            <DataTableLazyDevices 
              table="reports"
              groupId={false} 
              dateQuery={day} 
            />
          </div>
          <div className={styles.spaceSearch}>
            <BoxChart
              margin="auto"
              width="100%"
              height="100%"
              heightBody="90%"
              heightHeader="10%"
              title="Últimos Buscados"
              icon="pi pi-history"
            >
              <div className={styles.tops_container}>
                <div className={styles.top3_container}>
                  {searchLast && searchLast.length !== 0 ? (
                    <Info
                      icon="pi pi-bookmark"
                      title="Sin data a mostrar"
                      value="0 visitas"
                      width="100%"
                      height="30%"
                      marginBottom="2rem"
                    />
                  ) : (
                    <>
                      {top3 && top3 !== []
                        ? top3.map((element, index) => (
                            <Info
                              key={index}
                              title={element.url}
                              icon="pi pi-bookmark"
                              width="100%"
                              height="30%"
                              marginBottom="2rem"
                            />
                          ))
                        : null}
                    </>
                  )}
                </div>

                {top10 && top10 === [] ? null : (
                  <div className={styles.top10_container}>
                    <VerticalBar
                      labels={labelsTop10}
                      title="Conexiones"
                      data={dataTop10}
                      backgroundColor={"#3F9BFF"}
                    />
                  </div>
                )}
              </div>
            </BoxChart>
          </div>
          <div className={styles.safeW}>
            <BoxChart
              margin="auto"
              width="100%"
              height="30rem"
              heightHeader="20%"
              heightBody="80%"
              title="Visitas"
              icon="pi pi-tags"
            >
              {querys && querys === null ? <p>Sin data a mostrar</p> : null}
              {querys && querys !== null ? (
                <Line data={querys} ubication="SfWb-home" />
              ) : null}
            </BoxChart>
          </div>
        </div>
      </div>
    </>
  );
}
